const url = (imageId: string) => {
  return `https://imagedelivery.net/Knvys86jJSy4RAoUACMsVg/${imageId}/public`;
};

export const images = {
  collage: url('b435d65d-5170-4d51-3aa0-5b9bb7f3fc00'),
  'map-to-camp67': url('caddfaa7-49c7-4828-f4bd-03c498dd2e00'),
  'experience-1': url('56b81d94-5ba6-46fd-a551-1e4165d3d200'),
  'experience-2': url('a3a99ffb-31c5-4a6f-c1e2-cd518983c200'),
  'experience-3': url('d98b3a97-166d-49ef-7c1a-42ccb9ffe100'),
  'experience-4': url('475ac0cd-a96a-45d3-ecb6-0524b3cb3000'),
  'family-tent': url('eea87f85-a66c-4282-aaa8-ca11bec94400'),
  'gallery-1': url('42982f39-8e3e-4e2e-c163-6173ebde7500'),
  'gallery-2': url('c0ca1c79-5497-4792-cd58-145997b0ea00'),
  'gallery-3': url('0cccdf3a-f019-4c19-9d6e-20d3209c1000'),
  'gallery-4': url('4b5aebef-8be9-4d19-0533-7b863a01e500'),
  'gallery-5': url('ef5e19d6-b23f-4e70-2dbc-5532eb21d700'),
  'gallery-6': url('561f74d3-14f8-4bf0-e8c4-892559bc2900'),
  'gallery-7': url('fd09c1cb-10c7-43db-a462-b4c75d321b00'),
  'gallery-8': url('2cd1c6f1-5ee5-46cd-0858-de73a1906e00'),
  ganges: url('5a556aeb-febe-4542-fc5f-9c46d72c0700'),
  'large-circle-footer': url('f895f611-bac9-4dde-fe71-b7699f373700'),
  'logo-grey': url('a5058f12-770f-4f78-af89-47ab4ca93f00'),
  'logo-white': url('3fc3405b-8c7e-4030-6436-6646a245ea00'),
  logo: url('e6bab38a-2c5f-412a-5415-9f7f82777700'),
  'twin-tent-1': url('6f371645-e26f-4a9e-4c75-65f066ba7800'),
  'twin-tent-2': url('a1dcb958-044d-4448-7596-5e2abdee3c00'),
  'who-are-we-hero': url('46d8e9cd-8e0d-4752-3161-23da80def500'),
  'who-are-we-hero-v2': url('41a65530-e147-4cb4-4467-8d5383e93b00'),
  'camp67-and-kumbh-hero': url('709efd0b-a99f-4b7e-7611-b4612a341300'),
  'camp67-and-the-land-hero': url('a2609867-7df9-41bb-0289-b0bd445f0a00'),
  'significance-of-kumbh-hero': url('95b628c5-1af4-4a6d-bba7-525a4be49c00'),
  'paush-purnima-hero': url('894f88f7-fb65-4762-cc4b-5732df171700'),
  'makar-sankranti-hero': url('3e024d93-052f-4bcf-1a88-1ddd42fd4800'),
  'mauni-amavasya-hero': url('27a89b13-1d5f-4d3b-06f6-919e706cef00'),
  'basant-panchami-hero': url('b2136a9a-5fca-4b8d-cf2f-7d02d0426a00'),
  'achal-saptami-hero': url('fb30a231-661b-4273-48ea-7f75333e5900'),
  'maghu-purnima-hero': url('cc444edc-034b-478e-3e0a-0b8c9770ce00'),
  'maha-shivratri-hero': url('d8aa0bcf-56ca-4da8-3b5a-4bc8675f9800'),
  'logo-dark': url('e9f193ff-26ef-4d57-6c8e-169bd5e0e700'),
  menu: url('81e920b0-b571-4a5c-a946-4dba796ede00'),
  'dark-menu': url('1c911880-7d33-47a8-0e21-539dd12fa600'),
  'close-menu': url('d4d1d1d7-39c6-40d7-83ec-e9274a87a700'),
  'icon-shield': url('c09f0079-9560-4104-55cc-8d9505e6d400'),
  'incin-logo': url('8821daa2-06bf-4581-0984-ff5e4a492a00'),
  ficci: url('f333d855-7567-4cd8-e957-7bdd9bc77800'),
  icc: url('3307b8e9-4602-43f0-e87e-fddd5d8a9b00'),
  ycb: url('98c5ffce-0872-4c8c-b305-4e2807323800'),
  'mot-govin': url('31025c91-2058-4976-fee1-95435109cc00'),
  'mot-medal': url('59c414fb-203a-4f51-cc97-6b127549c800'),
  'ministry-ayush': url('70b44f09-ad9f-40ea-be88-f781a34c4400'),
  iato: url('9930b5bd-b311-4897-0f81-7694fc4d6400'),
  'header-Sarkar': url('3c127ba1-a74a-4c48-8eda-51b0f679ba00'),
  'header-james': url('83d3b5f6-92e6-4fd1-474e-cd3ba565ae00'),
  'header-Swami-gaushala': url('c760e5a0-6ae6-4aab-e526-6072b6151600'),
  'header-meditating': url('62a8f674-d974-42c2-9ab6-5e1acc1b4e00'),
  hygienic: url('1301ee29-c814-4f2c-36df-7ccb9de1df00'),
  peaceful: url('dd4b34b1-d1c0-4863-a58d-5a61a7832500'),
  safe: url('21517f18-db9a-4716-5f58-5922a6c7e800'),
  'avatar-gabriella': url('b194211e-043f-41c4-53c8-3ea94cc9c300'),
  'avatar-rajesh': url('8699fcd3-8b83-47da-a1ed-e61cce9a2000'),
  'avatar-pawars': url('966e715a-16e2-477d-1d5c-f9f406c99b00'),
  'avatar-richard': url('7ff2bd53-aacc-4472-2091-c5ef8c31d000'),
  'avatar-bobby': url('a7184755-f8d2-425c-047f-7359c4101100'),
  'testimonials-thumbnail': url('c9b4e46d-e6b6-45a5-b2b2-60d64b9b2a00'),
  'dorm-single': url('94c804aa-29cd-4c12-d1cb-6ac381e25400'),
  // Renders & new tent images
  'family-tent-v2-1': url('54f96a60-e7a1-4ac6-762f-0d291aa1d600'),
  'family-tent-v2-2': url('fff241fa-6475-45e9-92e1-563ef9660500'),
  'family-tent-v2-3': url('83d0f448-ee1a-4a15-196c-3b0d040c8300'),
  'family-tent-v2-4': url('34cd7aa2-4150-4b3a-6c74-7e4ce8da3400'),
  'family-tent-v3-1': url('c2cccf33-fcca-4f14-c5f9-0681f9d94d00'),
  'family-tent-v3-2': url('da7ca4f5-bb3e-4c73-ea29-8f2f23101300'),
  'family-tent-v3-3': url('20ff9888-c889-4a59-5491-0699f9e1db00'),
  'family-plus-tent-v2-1': url('e2fca4f2-b295-45e0-06d2-c4ccc2fd4000'),
  'family-plus-tent-v2-2': url('0d81151c-e264-45f1-d0fb-2f4bbd4d2000'),
  'family-plus-tent-v2-3': url('514b0457-5aa7-4e12-dfc6-9449fe1f9300'),
  'family-plus-tent-v2-4': url('32617c09-89c1-4cee-f859-e194a8500900'),
  'twin-tent-v2-1': url('c01ae0d3-a220-4f53-85c6-cbb554c14100'),
  'twin-tent-v2-2': url('2c75c4bf-c94e-40cb-f019-894217348a00'),
  'twin-tent-v2-3': url('f006fb0c-34f1-429a-b112-a1b0a1b70600'),
  'twin-tent-v3-1': url('6e3621ff-4e82-47d6-bd1b-862f984f2f00'),
  'twin-tent-v3-2': url('2beb25aa-6063-4ec3-2992-cbcf8b79d700'),
  'twin-tent-v3-3': url('dbae82f7-48a1-4f72-1b94-8aba475a4000'),
  'premium-twin-tent-1': url('6ca73fa1-0db0-4037-1cf1-eaaac02b1a00'),
  'premium-twin-tent-2': url('c5596181-982e-430f-f668-3c2148e42d00'),
  'premium-twin-tent-3': url('94027f0f-a374-4fe7-efca-85b151499700'),
  'dorm-singles-v2': url('8e9b63f7-5b13-4bb2-294a-5a27a7e15000'),
  'dorm-singles-v2-2': url('86133867-b523-4563-24a2-61a38caeec00'),
  'dorm-singles-v3-1': url('690fba78-1545-4345-05b8-23f6c8a72500'),
  'dorm-singles-v3-2': url('19e99420-00b8-4eba-d6ed-a05dee615600'),
  'dorm-singles-v3-3': url('e4c37d1e-afaf-4a86-7b2b-82e9d26c4800'),
  'dorm-bunks-v2': url('6c78d1cd-bfb1-4161-aa3e-d168342b5800'),
  'dorm-bunks-v2-2': url('b4086c93-3192-4138-7a59-a72285387600'),
  'dorm-bunks-v2-3': url('aaaec723-4593-4f2f-91d6-8ae1fbae4800'),
  // New header hero
  'hero-cover-v2':
    'https://imagedelivery.net/Knvys86jJSy4RAoUACMsVg/965e05c7-148a-4de2-1ef7-b7af4974de00/public',
};
